import styled, { themeGet, css, } from "../../shared/styled";

interface TableCellProps {
    isCompleted: boolean;
    children: React.ReactNode;
  }
export const Container = styled.div`
    margin: 50px 110px 5px;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
`;

export const TableHeader = styled.th`
    padding: 12px 15px;
    background-color: white;  
    color: black;  
    text-align: left;
    font-weight: bold;
    border: 1px solid #ddd;
`;


export const TableCell = styled.td<TableCellProps>`
  padding: 12px 15px;
  border: 1px solid #ddd;

  ${({ isCompleted }) =>
    isCompleted
      ? css`
          color: green; 
        `
      : css`
          color: black; 
        `}
`;

export const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f2f2f2; 
    }
    &:hover {
        background-color: #ddd;  
    }
`;