import React from 'react';
import { useState,useEffect, useReducer } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { List, Add, AttachFile, Delete } from "@material-ui/icons";
import { Button, Col, Row } from "../../components";
import { Heading } from "../../components/index";
import Content from "../../layouts/content";
import { Container,  } from "./style";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getCompaniesList } from "../../redux/actions/memberAction";
import axios, { AxiosError } from 'axios';
import { SERVICEURL } from '../../appconfig';
import { Table, TableHeader, TableCell, TableRow,  } from "./style";
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';

interface Status {
    id: number;
    name: string;
}
interface TotalCost {
    CustomerId: number;
    TotalCost: number;
}
interface Payment {
    ProjectId: number;
    ProjectName: string;
    InvoicedAmount: number;
    RemainingAmount: number;
    ProjectStartDate: string;
    ProjectEndDate: string;
    ProjectCurrentCompletionDate: string;
    ProjectCost: number;
}
interface CostDetail {
    Period: string;
    TotalCost: number; 
    
}



const useStylesButton = makeStyles((theme: Theme) =>
    createStyles({
        buttonList: {
            fontWeight: "bold",
            margin: "7px",
            display: "flex", 
            justifyContent: "flex-end",
        },
        buttonTransfer: {
            fontWeight: "bold",
            margin: "7px",
        },
        buttonSave: {
            margin: "7px",
            fontWeight: "bold",
            position: "relative",
            float: "right",
        },
        buttonIcon: {
            marginRight: "5px",
        },
        buttonIconDelete: {
            position: "relative",
            textAlign: "center",
            color: "danger",
        },
        buttonIconUpdate: {
            position: "relative",
            textAlign: "center",
        },
        TextFieldControl: {
            overflowWrap: "break-word",
            backgroundColor: "rgb(255, 255, 255)",
            backgroundClip: "border-box",
            border: "1px solid transparent",
            borderRadius: "4px",
            boxShadow: "rgb(26 108 225 / 10%) 0px 0px 25px",
            padding: "15px 15px 15px 15px",
            height: "100%",
        },
        colorBackground: {
            backgroundColor: "rgb(247,250,255)",
        },
    })
);
const useStylesHeader = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            marginLeft: "117px",
            height: "40px"
        },
        scroll: {
            overflowY: "scroll",
            height: "100vh",
        },
        TextFieldControl: {
            overflowWrap: "break-word",
            backgroundColor: "rgb(255, 255, 255)",
            backgroundClip: "border-box",
            border: "1px solid transparent",
            borderRadius: "4px",
            boxShadow: "rgb(26 108 225 / 10%) 0px 0px 25px",
            padding: "15px 15px 15px 15px",
            height: "100%",
        },
        colorBackground: {
            backgroundColor: "rgb(247,250,255)",
        },
    })
);
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        scroll: {
            overflowY: "scroll",
            height: "100vh",
        },
        table: {
            textAlign: "center",
        },
    })
);





const ProjectReport: React.FC = () => {

    const dis = useDispatch();

    const classesHeader = useStylesHeader();
    const classesName = useStylesButton();
    const classesButton = useStylesButton();
    const classesStyle = useStyles();


    type State = {
        
        companyId: number | null;
        projectId: number | null;
        loading: boolean;
        isActive: boolean | null;
        period: Date | null;
        

    }
    const initialState: State = {
        
        companyId: null,
        projectId: 0,
        loading: false,
        isActive: null,
        period: new Date(),
        

    }

    type Action =
    | { type: 'setCompanyId'; payload: number | null }
    | { type: 'setProjectId'; payload: number | null }   
    | { type: 'setLoading'; payload: boolean }
    | { type: 'setIsActive'; payload: boolean | null }
    | { type: 'setPeriod'; payload: Date | null };


    


    const reducer = (state: State, action: Action): State => {
        switch (action.type) {

            case 'setCompanyId':
                return {
                    ...state,
                    companyId: action.payload,
                };      

            case 'setProjectId':
                return {
                    ...state,
                    projectId: action.payload,
                };

            case 'setLoading':
                return {
                    ...state,
                    loading: action.payload,
                };
            
                case 'setIsActive': 
                return {
                    ...state,
                    isActive: action.payload,
                };

                case 'setPeriod':
                return {
                    ...state,
                    period: action.payload,
                };
               
          
        }
    };


    
    
    const [company, setCompany] = React.useState<any | null>(2);
    const { companiesList } = useSelector((state: RootStateOrAny) => state.companies);
    const [companyİnput, setCompanyİnput] = React.useState("RDC PARTNER");
    const [companyOption, setCompanyOption] = React.useState("RDC PARTNER");
    const [selectedStatus, setSelectedStatus] = useState<Status | null>(null);
    const [projectList, setProjectList] = useState([]);
    const [projectValue, setProjectValue] = React.useState<any | null>();
    const [projectOptionName, setProjectOptionName] = React.useState("");
    const [state, dispatch] = useReducer(reducer, initialState);
    const mytoken = localStorage.getItem("token");
    const [openError, setOpenError] = React.useState(false);
    const [progressPaymentList, setProgressPaymentList] = useState<any[]>([]);
    const [totalCost, setTotalCost] = useState<number | null>(null);
    const [currentMonthTotalCost, setCurrentMonthTotalCost] = useState<number | null>(null);
    const [estimatedCost, setEstimatedCost] = useState<number | null>(null);
    const [detailedCosts, setDetailedCosts] = useState<any[]>([]);
    const [mostRecentPeriod, setMostRecentPeriod] = useState<string>('');
    const [invoiceDate, setInvoiceDate] = useState<string | null>(null);

    

    
  

    function getCompanies() {
        dis(getCompaniesList());
    }

    useEffect(() => {
        getCompanies();
    }, []);




    const getProject = (isActive: boolean | null) => {
        const input = {
            CompanyID: state.companyId,
            IsActive: isActive,
        };
        axios.post(SERVICEURL + 'api/Data/GetProjectList', input, HEADER)
            .then(res => {
                if (res.data != undefined && res.data != null) {

                    setProjectList(res.data.CustomerList);
                    dispatch({
                        type: 'setLoading',
                        payload: false,
                    });
                }

            })
            .catch((reason: AxiosError) => {
                if (reason.response!.status === 401) {
                    

                    setOpenError(true);

                    dispatch({
                        type: 'setLoading',
                        payload: false,
                    });
                } else {
                    console.log("Handle else:", reason.message);
                }

            })

    };
   
        useEffect(() => {
            getProject(state.isActive);
        }, [state.isActive, state.companyId]);




        const getProgressPaymentList = () => {
            const input = {
                CompanyId: state.companyId,
                ProjectId: state.projectId,
                Period: state.period,
                isActive: state.isActive, 
            };
            console.log("Gönderilen input:", input);
    
            axios.post(SERVICEURL + 'api/Finance/GetProjectProgressPaymentList', input, HEADER)
                .then(res => {
                    console.log("API Yanıtı:", res.data);
                    if (res.data) {
                        setProgressPaymentList(res.data);
                        dispatch({
                            type: 'setLoading',
                            payload: false,
                        });
                    }
                })
                .catch((reason: AxiosError) => {
                    if (reason.response!.status === 401) {
                        setOpenError(true);
                        dispatch({
                            type: 'setLoading',
                            payload: false,
                        });
                    } else {
                        console.log("Handle else:", reason.message);
                    }
                });
        };

        const getLatestInvoiceDate = () => {
            const input = { ProjectId: state.projectId }; 
            axios.post(SERVICEURL + 'api/Finance/GetLatestInvoiceDate', input, HEADER)
                .then(res => {
                    console.log(res.data);  
                    if (res.data && res.data.InvoiceDate) {  
                        console.log("En son fatura tarihi: ", res.data.InvoiceDate);                      
                        const formattedDate = new Date(res.data.InvoiceDate).toLocaleDateString();
                        console.log("Formatted En son fatura tarihi: ", formattedDate);
                        setInvoiceDate(formattedDate);
                    } else {
                        console.log("Fatura bulunamadı");
                    }
                })
                .catch((error) => {
                    console.log("API hatası: ", error);
                });
        };
        
        useEffect(() => {
            getLatestInvoiceDate();
          }, []); 
        
        



        


        const Costt = () => {
            const input = {
                CompanyId: state.companyId,
                ProjectId: state.projectId,
                Period: state.period,
                isActive: state.isActive, 
            };
        
            console.log("Gönderilen input:", input);
        
            axios.post(SERVICEURL + "api/Report/CostReport", input, HEADER)
                .then(res => {
                    console.log("Maliyet API Yanıtı:", res.data);
        
                    if (res.data) {
                        const currentProjectCost = res.data.totalCosts.find((cost: TotalCost) => cost.CustomerId === state.projectId);
                        setTotalCost(currentProjectCost ? currentProjectCost.TotalCost : 0);
        
                        const currentMonthCost = res.data.currentMonthTotalCosts.find((cost: TotalCost) => cost.CustomerId === state.projectId);
                        setCurrentMonthTotalCost(currentMonthCost ? currentMonthCost.TotalCost : 0);
        
                        const projectDetails = res.data.detaillist.filter((item: any) => item.CustomerId === state.projectId);
                        console.log("Seçilen Proje Detayları:", projectDetails);
        
                        if (projectDetails.length > 0) {
        
                          
                            const relevantDetails: CostDetail[] = projectDetails.map((item: any) => ({
                                ProjectId: state.projectId,
                                Period: item.period,
                                Giro: item.giro,
                                TotalCost: item.totalcost,
                                Brut: item.brüt
                            }));
        
                          
                            const sortedDetails = relevantDetails.sort((a: CostDetail, b: CostDetail) => {
                                const [monthA, yearA] = a.Period.split('/').map(Number); 
                                const [monthB, yearB] = b.Period.split('/').map(Number); 
                                
                                if (yearA !== yearB) {
                                    return yearA - yearB;
                                }
                                
                                return monthA - monthB;
                            });
        
                            
                            const detailsWithPreviousCost = sortedDetails.map((item: CostDetail, index: number, array: CostDetail[]) => {
                                const previousCost = index > 0 ? array[index - 1].TotalCost : 0; 
                                return {
                                    ...item,
                                    PreviousCost: previousCost
                                };
                            });

                            const mostRecentPeriod = detailsWithPreviousCost.length > 0
                            ? detailsWithPreviousCost[detailsWithPreviousCost.length - 1].Period
                            : '';
                        setMostRecentPeriod(mostRecentPeriod);
        
                            console.log("Filtrelenmiş detaylar:", detailsWithPreviousCost);
                            setDetailedCosts(detailsWithPreviousCost);
                        } else {
                            setDetailedCosts([]);
                        }
                    }
        
                    dispatch({
                        type: 'setLoading',
                        payload: false,
                    });
                })
                .catch((reason: AxiosError) => {
                    if (reason.response!.status === 401) {
                        setOpenError(true);
                        dispatch({
                            type: 'setLoading',
                            payload: false,
                        });
                    } else {
                        console.log("Handle else:", reason.message);
                    }
                });
        };

        
        

       




        const handleCompanies = (event: any, newValue: any | null) => {
            console.log("Selected Company:", newValue);
            if (newValue != null) {
                setCompany(newValue.id);
                dispatch({ type: 'setCompanyId', payload: newValue.id }); 
            } else {
                setCompany(null);
                dispatch({ type: 'setCompanyId', payload: null }); 
            }
        };

        const handleChangeCompanyInput = (
            event: any,
            newValueInput: any | null
        ) => {
            if (newValueInput != null && newValueInput != undefined) {
                setCompanyOption(newValueInput);
                setCompanyİnput(newValueInput);
            }
            if (newValueInput == null) {
                setCompanyİnput(newValueInput);
            }
        };
    




        const handleChangeProject = (event: any, newValue: any | null) => {
            if (newValue != null && newValue != undefined) {
                setProjectValue(newValue);
                dispatch({
                    type: 'setProjectId',
                    payload: newValue.id,
                });
            }
            if (newValue == null) {
                setProjectValue(newValue);
                dispatch({
                    type: 'setProjectId',
                    payload: 0,
                });
            }
        };
    


    const HEADER = {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + mytoken }
    }





    const statusList: Status[] = [
        { id: 1, name: 'Aktif' },
        { id: 2, name: 'Pasif' },
    ];

    const handleStatusChange = (event: any, newValue: Status | null) => {
        setSelectedStatus(newValue);
        
        
        const isActive = newValue ? newValue.id === 1 : null;
        
        dispatch({
            type: 'setIsActive',
            payload: isActive,
        });
    
        
        getProject(isActive);
    };

    const formatDateString = (dateString: string) => {
        const [day, month, year] = dateString.split('/');
        return `${year}-${month}-${day}`; 
    };
    
    const calculateExtraTime = (ProjectCurrentCompletionDate: string, ProjectEndDate: string): number => {
        const actualDate = new Date(formatDateString(ProjectCurrentCompletionDate));
        const end = new Date(formatDateString(ProjectEndDate));
    
        if (isNaN(actualDate.getTime()) || isNaN(end.getTime())) {
            return 0; 
        }
    
        const timeDifference = actualDate.getTime() - end.getTime();
        const dayDifference = timeDifference / (1000 * 3600 * 24);
        const monthDifference = dayDifference / 30;
    
        return monthDifference;
    };

    const calculateRemainingMonths = (projectEndDate: string): number => {
        const end = new Date(formatDateString(projectEndDate));
        const today = new Date();
    
        if (isNaN(end.getTime())) {
            return 0; 
        }
    
        const timeDifference = end.getTime() - today.getTime();
        const dayDifference = timeDifference / (1000 * 3600 * 24);     
        const monthDifference = dayDifference / 30;
    
        return Math.max(0, monthDifference); 
    };
    
   

useEffect(() => {
    if (currentMonthTotalCost !== null && totalCost !== null) {
        const totalRemainingMonths = progressPaymentList.reduce((total, payment) => {
            return total + calculateRemainingMonths(payment.ProjectEndDate);
        }, 0);
        
        
        const estimated = (currentMonthTotalCost * totalRemainingMonths) + totalCost;

        
        console.log("Son Ayın Maliyeti:", currentMonthTotalCost);
        console.log("Kalan Süre (Ay):", totalRemainingMonths);
        console.log("Maliyet:", totalCost);
        console.log("Tahmini Maliyet:", estimated);

        setEstimatedCost(estimated);
    }
}, [currentMonthTotalCost, totalCost, progressPaymentList]);


const calculateKZ = (payment: Payment) => {
    const grossKZ = (totalCost !== null) ? (payment.InvoicedAmount - totalCost) : null; 
    const estimatedKZ = (estimatedCost !== null) ? (payment.ProjectCost - estimatedCost) : null; 
    return { grossKZ, estimatedKZ };
};




const convertDateToISOFormat = (dateStr: string): string => {
    const [day, month, year] = dateStr.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

const calculateRemainingMonthsPeriod = (period: string, projectEndDate: string): number | string => {
    //console.log(`Dönem bilgisi: ${period}`);
   
    const [month, year] = period.split('/').map(Number);
    const periodDate = new Date(year, month - 1, 1); 
    //console.log(`Period Tarihi: ${periodDate}`);
    
    const endDateStr = convertDateToISOFormat(projectEndDate);
    const endDate = new Date(endDateStr);
    //console.log(`Bitiş Tarihi (EndDate objesi): ${endDate}`);
    
    if (isNaN(periodDate.getTime()) || isNaN(endDate.getTime())) {
        return 'Bilinmiyor';
    }

    const timeDiff = endDate.getTime() - periodDate.getTime(); 
   // console.log(`Zaman farkı (milisaniye): ${timeDiff}`);
    const dayDiff = timeDiff / (1000 * 3600 * 24); 
    //console.log(`Gün farkı: ${dayDiff}`);
    return dayDiff / 30; 
};

const isPeriodCompleted = (period: string) => {
    const [month, year] = period.split('/').map(Number);
    const periodDate = new Date(year, month - 1, 1); 
    const today = new Date();

    return periodDate <= today;
};





    const handleListClick = () => {
        if (state.companyId) { 
            dispatch({ type: 'setLoading', payload: true });
            getProgressPaymentList(); 
            Costt();
            getLatestInvoiceDate();
        } else {
            alert("Lütfen bir şirket seçin!"); 
        }
    };
    




    
    




    const Nav = React.lazy(() => import("../../layouts/nav/Nav"));

    return (
        <>
            <div className={classesStyle.scroll}>
                <Nav />

                <div className={classesName.colorBackground}>
                    <Content>
                        <Heading as="h5" mb="5px" className={classesHeader.header}>
                            Proje Rapor
                        </Heading>
                        <Container className={classesName.TextFieldControl}>
                        <Row>
                             <Col lg={4} md sm xs mb={10}>
                                <FormControl fullWidth variant="outlined">                              
                                {
                                    Object.values(companiesList).length > 0 &&

                                    <Autocomplete
                                        options={Object.values(companiesList.data.Companies).map((companiesItem: any) => companiesItem)}
                                        getOptionLabel={(options) => options.name == undefined ? companyOption : options.name}
                                        value={company == null ? 0 : company} 
                                        onChange={handleCompanies} 
                                        inputValue={companyİnput} 
                                        onInputChange={handleChangeCompanyInput}    
                                        renderInput={params => (<TextField
                                            variant="outlined"
                                            {...params}
                                            label="Şirket"
                                            
                                        />
                                        )}
                                    />
                                }
                                </FormControl>
                                
                            </Col>


                            <Col lg={4} md sm xs mb={10}>
                                <FormControl fullWidth variant="outlined">
                                    <Autocomplete
                                        options={statusList}  
                                        getOptionLabel={(option) => option.name}
                                        value={selectedStatus}
                                        onChange={handleStatusChange}
                                        renderInput={params => (<TextField
                                            variant="outlined"
                                            {...params}
                                            label="Proje Durumu"                                            
                                        />
                                        )}
                                    />
                                </FormControl>
                            </Col>

                                <Col lg={4} md sm xs mb={10}>
                                    <FormControl fullWidth variant="outlined">
                                        <Autocomplete
                                            options={projectList} 
                                            getOptionLabel={(options) => options.name == undefined ? projectOptionName : options.name}
                                            value={projectValue == null ? 0 : projectValue}  
                                            onChange={handleChangeProject}                                          
                                            renderInput={params => (<TextField
                                                variant="outlined"
                                                {...params}
                                                label="Proje "                                         
                                            />
                                            )}
                                        />
                                    </FormControl>
                                </Col>
                                <Col  lg md sm xs mb={10} className={classesButton.buttonList}>
                                    <Button iconButton size="lg" color="light" className={classesButton.buttonList} onClick={handleListClick}  >
                                        <List width={18} height={18} className={classesButton.buttonIcon} />
                                        LİSTELE
                                    </Button>
                                </Col >

                                
                                <Col lg={12} md={12} sm={12} xs={12} mb={10}>
    {progressPaymentList.length > 0 ? (
        <div>
            {progressPaymentList.map(payment => {
                const extraTime = calculateExtraTime(payment.ProjectCurrentCompletionDate, payment.ProjectEndDate);
                const remainingMonths = calculateRemainingMonths(payment.ProjectEndDate);
                const { grossKZ, estimatedKZ } = calculateKZ(payment);
                const formatCurrency = (value: number) => {
                        return new Intl.NumberFormat('tr-TR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 3
                        }).format(value);
                    };

                return (
                    <div key={payment.ProjectId} style={{ marginBottom: '20px', padding: '20px', border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                        <h5 style={{ marginBottom: '15px', fontSize: '18px', color: '#333' }}><strong>Proje Adı:</strong> {payment.ProjectName}</h5>

                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '15px', marginBottom: '15px' }}>
                            <div><strong>Başlangıç Tarihi:</strong> {payment.ProjectStartDate}</div>
                            <div><strong>Proje Bedeli:</strong> {formatCurrency(payment.ProjectCost)}</div>
                            <div><strong>Bitiş Tarihi:</strong> {payment.ProjectEndDate}</div>
                            <div><strong>Faturalanan Tutar</strong> {invoiceDate && ` (${invoiceDate})`}: {formatCurrency(payment.InvoicedAmount)}</div>
                            <div><strong>Gerçekleşen Bitiş Tarihi:</strong> {payment.ProjectCurrentCompletionDate}</div>
                            <div><strong>Brüt K/Z</strong>{mostRecentPeriod && `(${mostRecentPeriod})`}: {grossKZ !== null ? grossKZ : 'Hesaplanmadı'}</div>
                            <div><strong>Kalan Tutar:</strong> {formatCurrency(payment.RemainingAmount)}</div>
                            <div><strong>Tahmini K/Z:</strong> {estimatedKZ !== null ? formatCurrency(estimatedKZ) : 'Hesaplanmadı'}</div>
                            <div><strong>Ek Süre (Ay):</strong> {extraTime.toFixed(2)}</div>
                            {/* <div><strong>Kalan Süre (Ay):</strong> {remainingMonths !== null ? remainingMonths.toFixed(2) : 'Hesaplanmadı'}</div>
                            <div><strong>Son Ayın Maliyeti:</strong> {currentMonthTotalCost !== null ? currentMonthTotalCost : 'Hesaplanmadı'}</div> */}
                        </div>

                        <div style={{ marginTop: '15px' }}>
                        <div>
                        <strong>Maliyet</strong>{mostRecentPeriod && `(${mostRecentPeriod})`}: {totalCost !== null ? formatCurrency(totalCost) : 'Hesaplanmadı'}
                        </div>

                        <div style={{ marginTop: '10px' }}>
                        <strong>Tahmini Maliyet:</strong> {estimatedCost !== null ? formatCurrency(estimatedCost) : 'Hesaplanmadı'}
                        </div>
                        </div>
                    </div>
                );
            })}
        </div>
    ) : null} 
</Col>
</Row>

{detailedCosts.length > 0 && (
    <Table>
        <thead>
            <TableRow>
                <TableHeader>Dönem</TableHeader>
                <TableHeader>
                    Ciro
                    <IconButton
                        size="small"
                        onClick={() => {
                            const projectId = detailedCosts.length > 0 ? detailedCosts[0]?.ProjectId : null;
                            if (projectId) {
                                window.open(`/projectProgressPaymentDetail/${projectId}`, '_blank');
                            } else {
                                console.error('Project ID bulunamadı');
                            }
                        }}
                        style={{ marginLeft: '10px' }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </TableHeader>
                <TableHeader>
                    Maliyet
                    <IconButton
                        size="small"
                        onClick={() => {
                            window.open('/profitabilityReportDetail', '_blank');
                        }}
                        style={{ marginLeft: '10px' }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </TableHeader>
                <TableHeader>Tahmini Maliyet </TableHeader>
                <TableHeader>Brüt k/z</TableHeader>
                {/* <TableHeader>Onceki Ay Maliyet</TableHeader> */}
                {/* <TableHeader>Bitiş Tarihi</TableHeader> */}
                {/* <TableHeader>Kalan Süre (Ay)</TableHeader> */}
            </TableRow>
        </thead>
        <tbody>
            {detailedCosts.map((detail, index) => {
                const project = progressPaymentList.find(p => p.ProjectId === detail.ProjectId);
                const projectEndDate = project ? project.ProjectEndDate : 'Bilinmiyor';

                const monthDifference = projectEndDate !== 'Bilinmiyor'
                    ? calculateRemainingMonthsPeriod(detail.Period, projectEndDate)
                    : 'Bilinmiyor';

                const estimatedCostPeriod = monthDifference !== 'Bilinmiyor'
                    ? ((detail.PreviousCost * Number(monthDifference)) + detail.TotalCost).toFixed(2)
                    : 'Hesaplanmadı';

                const isCompleted = isPeriodCompleted(detail.Period);

                const formatNumber = (value: number | string) => {
                    if (value === 'Hesaplanmadı') return 'Hesaplanmadı';
                    return new Intl.NumberFormat('tr-TR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(value as number);
                };

                return (
                    <TableRow key={index}>
                        <TableCell isCompleted={isCompleted}>{detail.Period}</TableCell>
                        <TableCell isCompleted={isCompleted}>{formatNumber(detail.Giro)}</TableCell>
                        <TableCell isCompleted={isCompleted}>{formatNumber(detail.TotalCost)}</TableCell>
                        <TableCell isCompleted={isCompleted}>{formatNumber(estimatedCostPeriod)}</TableCell>
                        <TableCell isCompleted={isCompleted}>{formatNumber(detail.Brut)}</TableCell>
                        {/* <TableCell>{detail.PreviousCost}</TableCell> */}
                        {/* <TableCell>{projectEndDate}</TableCell> */}
                        {/* <TableCell>{monthDifference}</TableCell> */}
                    </TableRow>
                );
            })}
            <TableRow>
                <TableCell isCompleted={false}>Toplam</TableCell>
                <TableCell isCompleted={false}>
                    {detailedCosts.reduce((acc, detail) => acc + Number(detail.Giro), 0)}
                </TableCell>
                <TableCell isCompleted={false}>
                    {detailedCosts.reduce((acc, detail) => acc + Number(detail.TotalCost), 0)}
                </TableCell>
                <TableCell isCompleted={false}>
                    {detailedCosts.reduce((acc, detail) => {
                        const project = progressPaymentList.find(p => p.ProjectId === detail.ProjectId);
                        const projectEndDate = project ? project.ProjectEndDate : 'Bilinmiyor';
                        const monthDifference = projectEndDate !== 'Bilinmiyor'
                            ? calculateRemainingMonthsPeriod(detail.Period, projectEndDate)
                            : 'Bilinmiyor';

                        const estimatedCostPeriod = monthDifference !== 'Bilinmiyor'
                            ? (detail.PreviousCost * Number(monthDifference)) + detail.TotalCost
                            : 0;

                        return acc + estimatedCostPeriod;
                    }, 0).toFixed(2)}
                </TableCell>
                <TableCell isCompleted={false}>
                    {detailedCosts.reduce((acc, detail) => acc + Number(detail.Brut), 0)}
                </TableCell>
            </TableRow>
        </tbody>
    </Table>
)}

</Container>
</Content>
</div>
</div>
</>
);
};

export default ProjectReport;
        